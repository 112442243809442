.rc-table-content {
  border: none;
}

.rc-table-cell {
  border: none;
}

.rc-table-cell:hover {
  border-radius: 5px;
  background-color: #dcf3ec !important;
}

.rc-table td {
  border: none;
  padding: 8px;
  background: transparent !important;
  white-space: nowrap;
}

.rc-table tr.dropzone td {
  background-color: #1dd19f40 !important;
  border: 3px dashed white;
  padding: 5px;
}

.rc-table-row-expand-icon {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
