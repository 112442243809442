::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  -webkit-transform: scaleX(10);
  background-color: transparent;
}

:hover::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #787f8b;
  border: none;
  box-shadow: none !important;
}

:hover::-webkit-scrollbar-thumb {
  background: rgba(100, 108, 122, 0.84);
}

:active::-webkit-scrollbar-thumb {
  background: #aecff0;
}
